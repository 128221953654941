<template>
  <div>
    <form-section
      :title="$t('courierSet.sideNavigation.additionalServices')"
      :tooltip="{ icon: 'mdi-help-circle-outline' }"
    >
      <template v-slot:message>
        <AdditionalServicesTooltipMessage />
      </template>
      <div
        v-for="serv in courierAddtionalServices"
        :key="serv.id"
      >
        <service-field
          :id="serv.id"
          :label="serv.name"
          :remark="serv.remark"
          :messages="serv.description"
          :code="serv.serviceId"
          :selected-service="isSelected(serv)"
          :active-service="isActive(serv)"
          :visible-service="isVisible(serv)"
          :parameters="serv.parameters"
          @@activate="activate"
          @@deactivate="deactivate(serv)"
          @@update="updateService"
        >
          <div
            v-if="serv.id === 1 && isSelected(serv)"
            class="additional-inputs"
          >
            <select-field
              v-model="getCod.sposobPobrania"
              :title="$t('courierSet.method')"
              :filed-items="methodItems"
              :hint="$t('courierSet.hintCOD')"
              :disabled="!isSelected(serv)"
              :rules="isSelected(serv) ? 'required' : ''"
            />
            <text-field
              v-model="getCod.tytulem"
              :title="$t('courierSet.paymentTitle')"
              :hint="$t('courierSet.hintPaymentTitle')"
              :disabled="!isSelected(serv)"
              :rules="isSelected(serv) ? 'required' : ''"
            />
          </div>

          <div
            v-if="serv.id === 2 && isSelected(serv)"
            class="additional-inputs"
          >
            <select-field
              v-model="getInsurance.rodzaj"
              :title="$t('courierSet.typeInsurance')"
              :filed-items="insuranceItems"
              :hint="$t('courierSet.hintInsurance')"
              :disabled="!isSelected(serv)"
              :rules="isSelected(serv) ? 'required' : ''"
            />
            <text-field
              v-model="getInsurance.kwota"
              :title="$t('courierSet.defaultAmount')"
              :hint="$t('courierSet.hintAmountInsurance')"
              :disabled="!isSelected(serv)"
              :rules="isSelected(serv) ? 'required|double:2|greaterThanZero' : ''"
            />
          </div>
        </service-field>
      </div>
    </form-section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { pocztapolskaAdditionalServices as courierAddtionalServices } from '@/constants/Speditors/PocztaPolska/AddtionalServices';
import additionalServicesMixin from '@/mixins/additionalServicesMixin';
import AdditionalServicesTooltipMessage from '@/components/tooltips/contents/AdditionalServicesTooltipMessage.vue';

export default {
  name: 'AdditionalServices',
  components: {
    AdditionalServicesTooltipMessage,
  },
  mixins: [additionalServicesMixin],
  props: {
    isNew: { type: Boolean, default: null },
  },
  data: () => ({
    additionalServices: [],
    courierAddtionalServices,
    unchangedSet: [],
    methodItems: [
      { id: 1, name: 'Przekaz' },
      { id: 2, name: 'Rachunek bankowy' },
    ],
    insuranceItems: [{ id: 1, name: 'Standard' }],
  }),
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getInsurance() {
      return this.getConfigurationSet().pocztaPolskaDefaultValues.ubezpieczenie;
    },
    getCod() {
      return this.getConfigurationSet().pocztaPolskaDefaultValues.pobranie;
    },
  },
  methods: {
    ...mapActions('spedition', ['setAdditionalServices']),
    update() {
      const state = this.additionalServices === this.unchangedSet ? 1 : 3;
      const serviceParameters = {
        clientEntityState: this.isNew || !this.id ? 4 : state,
        id: this.id,
        tenantId: this.oidcUser.tenantId,
        services: this.additionalServices,
      };
      this.setAdditionalServices({ additionalServices: serviceParameters });
    },
    clearAdditionalData(service) {
      if (service.id === 2) {
        this.getInsurance.rodzaj = '';
        this.getInsurance.kwota = '';
      } else if (service.id === 1) {
        this.getCod.sposobPobrania = '';
        this.getCod.tytulem = '';
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
