<template>
  <div>
    <form-section :title="$t('courierSet.authorizationData.title')">
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationValues.userName"
            :title="$t('courierSet.authorizationData.login')"
            rules="required"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getConfigurationValues.password"
            :title="$t('courierSet.authorizationData.password')"
            type="password"
            :rules="isNew ? 'required' : ''"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationValues.urlAdress"
            :title="$t('courierSet.authorizationData.urlAddress')"
            :rules="'required|url'"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getConfigurationValues.numerRachunkuBankowego"
            :title="$t('courierSet.authorizationData.iban')"
            rules="required|max:26"
          />
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.shipmentMethod.title')">
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationValues.configurationName"
            :title="$t('courierSet.shipmentMethod.configurationName')"
            rules="required|not_custom_shipment"
          />
        </v-col>
        <v-col>
          <select-field
            v-model="getConfigurationValues.courierCode"
            :title="$t('courierSet.shipmentMethod.deliverySpeditor')"
            rules="required"
            :filed-items="SHIPMENT_METHOD_CODES"
            disabled
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationValues.id"
            :title="$t('courierSet.shipmentMethod.configurationId')"
            rules="required"
            :disabled="true"
          />
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.serviceType.title')">
      <v-row>
        <v-col>
          <select-field
            v-model="getConfigurationValues.service"
            :title="$t('courierSet.serviceType.title')"
            :filed-items="services"
            rules="required"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { deliveryMethods } from '@/constants/Speditors/PocztaPolska/SendingMethods';
import { services } from '@/constants/Speditors/PocztaPolska/Services';
import { mapGetters } from 'vuex';
import { SHIPMENT_METHOD_CODES } from '@/constants/ShipmentMethodCodes.js';

export default {
  props: {
    isNew: { type: Boolean, default: false },
  },
  data() {
    return {
      SHIPMENT_METHOD_CODES,
      deliveryMethods,
      services,
      channelItems: [
        { id: 1, name: 'WEB_API' },
        { id: 2, name: 'MS' },
        { id: 3, name: 'REST_API ' },
      ],
    };
  },
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getConfigurationValues() {
      return this.getConfigurationSet();
    },
    getAllegroConfiguration() {
      return this.getConfigurationSet().pocztaPolskaDefaultValues.relatedToAllegro;
    },
  },
};
</script>

<style scoped></style>
